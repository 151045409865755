* {
  box-sizing: border-box;
  /* margin: 0;
  padding: 0; */
}

*::selection {
  color: white;
  background-color: rgb(4, 54, 160);
}

body {
  margin: 0;
  font-family: 'Lato', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu',
    'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

ul {
  list-style: none;
  padding: 0;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: 'Kufam', sans-serif !important;
}

p {
  color: rgb(66, 66, 66);
}

a {
  color: inherit;
  font-weight: bold;
  text-decoration: none;
  border-bottom: 0px solid rgba(74, 231, 87, 0.3);
  border-radius: 0.125rem;
  text-shadow: 0px 0px 0px rgba(74, 213, 231, 0.3);
  height: 1.5rem;
  transition: border-bottom 0.2s, text-shadow 0.2s, transform 0.1s;
}

a:hover {
  color: inherit;
  text-decoration: none;
  transform: translateY(-0.25rem) scale(1.1);
  border-bottom: 4px solid rgba(74, 231, 87, 0.3);
  text-shadow: 3px 3px 3px rgba(74, 213, 231, 0.3), -2px -2px 2px rgba(231, 74, 74, 0.3);
}

hr {
  width: 100%;
  margin: 1rem 0;
  border: 1px solid rgba(66, 66, 66, 0.1);
  box-shadow: 3px 3px 3px rgba(74, 213, 231, 0.3), -2px -2px 2px rgba(231, 74, 74, 0.3);
}

textarea,
textarea:active,
textarea:hover,
textarea:focus {
  box-shadow: none;
  border: none;
}

.icon {
  transform: translateY(15%);
}

.form-control {
  width: 100%;
  margin: 1rem 0;
  display: flex;
  flex-flow: column wrap;
}

.form-control label {
  font-weight: bold;
}

.form-control input {
  padding: 0.125rem;
}

.form-control input[type='submit'] {
  padding: 0.5rem;
  cursor: pointer;
  transition: all 0.2s;
}

.form-control input[type='submit']:hover {
  box-shadow: 3px 3px 3px rgba(74, 213, 231, 0.3), -2px -2px 2px rgba(231, 74, 74, 0.3);
  font-weight: bold;
}

.error {
  padding: 0.5rem;
  background: rgba(231, 74, 74, 0.3);
  border: 1px solid red;
  border-radius: 0.125rem;
}

@keyframes fadein {
  from {
    opacity: 0;
    transform: translateY(-1rem);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}
